@import "../env";
@import "../components/catalog/product-card";

:root {
  --imn: #{calc_fluid(-6,-10,320,768)};
  --imp: #{calc_fluid(6,10,320,768)};
}

.catalog {
  &-select {
    margin-top: 40px;
    width: 100%;
    border: 1px dashed $blu;
    padding: calc_fluid(28,50,768,1920) calc_fluid(28,75,768,1920) 50px;
    @include _768 {
        padding: calc_fluid(15,28,320,768) calc_fluid(25,28,320,768) 40px;
        margin-bottom: calc_fluid(-20,-5,320,768);
    }
    border-radius: 3px;
    &-header {
      display: flex;
      justify-content: space-between;
      @include _1170 {
        flex-flow: row wrap-reverse;
      }
      &-progress {
        width: 335px;
        display: flex;
        align-items: center;
        &-bar {
          width: 268px;
          @include _600 {
            width: 204px;
          }
          height: 8px;
          background: #DFE9F3;
          border-radius: 33px;
          &-inner {
            height: 100%;
            background: #0054A6;
            border-radius: 33px;
            width: 0;
            &.step1 {
              width: calc(100% / 3);
            }
            &.step2 {
              width: calc(100% - (100% / 3));
            }
          }
        }
        &__step {
          margin-left: calc_fluid(14,30,320,1920);
          font-weight: bold;
          font-size: calc_fluid(14,24,320,1920);
          line-height: 32px;
          color: $blu;
        }
      }
      &__title {
        @include _920 {
          margin-top: calc_fluid(20,30,320,768);
        }
        font-weight: 900;
        font-size: calc_fluid(20, 28, 320, 1920);
        line-height: calc_fluid(26, 40, 320,1920);
        color: $blk;
        & b {
          color: $blu;
        }
      }
    }
    &-search {
      margin-top: 25px;
      width: 571px;
      @include _768 {
        width: 100%;
      }
      height: calc_fluid(40,53,320,1920);
      border: 1px solid #CDCDCD;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      &-btn {
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        width: 64px;
        @include _768 {
          width: 50px;
        }
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        $csb: &;
        &__svg {
          width: 24px;
          height: 24px;
          @include _768 {
            width: 20px;
            height: 20px;
          }
          fill: $blu;
        }
        &:hover {
          #{$csb} {
            &__svg {
              fill: $red;
            }
          }
        }
      }
      &__input {
        border: none;
        appearance: none;
        width: 100%;
        height: 100%;
        font-size: 16px;
        line-height: 24px;
        padding: 0 64px 0 20px;
        color: #333;
        @include _768 {
          font-size: calc_fluid(12,16,320,768);
          line-height: calc_fluid(22,24,320,768);
          padding: 0 50px 0 15px;
        }
        &::placeholder {
          font-size: 16px;
          line-height: 24px;
          color: #878787;
          @include _768 {
            font-size: calc_fluid(12,16,320,768);
            line-height: calc_fluid(22,24,320,768);
          }
        }
      }
    }
    &-items {
      margin: 35px -15px -15px;
      @include _768 {
        margin: calc_fluid(5,10,320,768) var(--imn) var(--imn);
      }
      display: flex;
      flex-flow: row wrap;
      &.hidden {
        display: none;
      }
    }
    &-empty {
      display: none;
      margin-top: 40px;
      width: 100%;
      padding: calc_fluid(20,38,320,1920) calc_fluid(25,67,320,1920) calc_fluid(20,38,320,1920) calc_fluid(25,74,320,1920);
      align-items: center;
      justify-content: space-between;
      border: 1px solid #CCDDCC;
      border-radius: 3px;
      @include _1600 {
        flex-direction: column;
      }
      &.active {
        display: flex;
      }
      &-left {
        display: flex;
        align-items: center;
        &__svg {
          width: calc_fluid(60,80,768,1920);
          height: calc_fluid(60,80,768,1920);
          @include _768 {
            width: 100%;
            height: calc_fluid(55,60,320,768);
          }
          fill: none;
        }
        @include _768 {
          flex-flow: row wrap;
          align-items: center;
          justify-content: center;
        }
      }
      &-buttons {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        @include _1600 {
          margin-top: calc_fluid(35,10,768,1600);
          width: 100%;
        }
        @include _768 {
          flex-flow: row wrap;
        }
      }
      &-left, &-buttons {
        @include _1600 {
          width: 100%;
        }
      }
      &-find {
        width: 299px;
        height: calc_fluid(40,54,320,1920);
        flex-direction: column;
        &__top {
          font-weight: 900;
          font-size: 16px;
          line-height: 20px;
          @include _768 {
            font-size: calc_fluid(11,15,320,768);
            line-height: calc_fluid(15,19,320,768);
          }
          color: #FFFFFF;
        }
        &__bottom {
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          color: #FFFFFF;
          @include _768 {
            font-size: calc_fluid(10,13,320,768);
            line-height: 13px;
          }
        }
      }
      &__callback {
        width: 240px;
        height: calc_fluid(40,54,320,1920);
        margin-left: 26px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        @include _768 {
          font-size: calc_fluid(11,16,320,768);
          line-height: calc_fluid(15,24,320,768);
        }
        @include _600 {
          margin-left: 0;
          margin-top: 10px;
        }
      }
      &__btn {
        cursor: pointer;
        @include _1600 {
          width: calc(50% - 26px);
          @include _600 {
            width: 100%;
          }
        }
      }
      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #333;
        width: 381px;
        max-width: 381px;
        @include _1600 {
          width: unset;
        }
        margin-left: 36px;
        @include _768 {
          text-align: center;
          margin-left: 0;
          font-size: calc_fluid(16,18,320,768);
          line-height: calc_fluid(20,18,320,768);
          margin-top: 10px;
          @include _600 {
            font-weight: 700;
          }
        }
      }
    }
    &-more {
      display: flex;
      width: 100%;
      margin-top: 30px;
      justify-content: center;
      &.hidden {
        display: none;
      }
      &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 228px;
        height: 53px;
        color: $blu;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        @include _768 {
          height: calc_fluid(40, 53, 320, 768);
          font-size: calc_fluid(11, 16, 320,768);
          line-height: calc_fluid(15,24,320,768);
          width: calc_fluid(155,283,320,768);
        }
      }
      @include _768 {
        margin-top: calc_fluid(20,30,320,768);
      }
    }
    &-mark {
      margin: 15px;
      width: calc(100% / 8 - 30px);
      @include _768 {
        margin: var(--imp);
      }
      @include _1600 {
        width: calc(100% / 4 - 30px);
        @include _979 {
          width: calc(100% / 3 - 30px);
        }
        @include _768 {
          width: calc(100% / 3 - var(--imp) * 2);
        }
        @include _600 {
          width: calc(100% / 2 - var(--imp) * 2);
        }
      }
      border: 1px solid #CDCDCD;
      display: block;
      border-radius: 3px;
      overflow: hidden;
      padding-bottom: calc_fluid(30,28,768,1920);
      @include _768 {
        padding-bottom: calc_fluid(16.4,30,320,768);
      }
      $cpm: &;
      &__img {
        height: calc_fluid(140,135,768,1920);
        @include _768 {
          height: calc_fluid(78.5,140,320,768);
        }
        object-fit: contain;
        width: 100%;
      }
      &__title {
        margin-top: 17px;
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        word-break: break-word;
        text-align: center;
        text-decoration-line: underline;
        @include _600 {
          font-size: 11px;
          line-height: 15px;
        }
        color: #333333;
      }
      &.model {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 26px 10px;
        @include _768 {
          padding: calc_fluid(15,27,320,768) 10px;
        }
        #{$cpm}__title {
          margin-top: 0;
        }
      }
      &:hover {
        border: 1px solid $red;
        background-color: rgba(237, 24, 71, 0.16);
        & > * {
          position: relative;
          z-index: -1;
        }
        #{$cpm} {
          &__title {
            color: $red;
          }
        }
      }
    }
  }
  &__title {
    font-weight: 900;
    font-size: calc_fluid(22, 38, 320, 1920);
    line-height: calc_fluid(29,43,320,1920);
    margin-right: 30px;
    color: $blk;
  }
  &-header {
    margin-top: -16px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    &-left {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
    }
    &-image {
      height: 78px;
      width: 114px;
      padding: 10px;
      border: 1px solid #CDCDCD;
      border-radius: 3px;
      overflow: hidden;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__count {
      font-size: 20px;
      line-height: 60px;
      color: #878787;
      font-style: italic;
    }
  }
  &-wrapper {
    width: 100%;
    display: flex;
    @include _1170 {
      flex-flow: row wrap;
    }
    margin-top: 18px;
  }
  &-filters {
    width: 270px;
    min-width: 270px;
    max-width: 270px;
    background: #fff;
    @include _1170 {
      width: 100%;
      max-width: unset;
      min-width: unset;
      &.active {
        left: 0;
      }
      &-main {
        border-bottom: 1px solid $borderCD;
      }
    }
    &-close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 76px;
      width: 100%;
      background: $blu;
      position: relative;
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 27px;
      &__svg {
        position: absolute;
        left: 30px;
        top: 29px;
        fill: #fff;
        height: 18px;
        width: 18px;
        transform: rotate(90deg);
      }
    }
    &-type {
      &-header {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: $blu;
        border: 1px solid $blu;
        background: #EBF2F8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        &.first {
          border-radius: 3px 3px 0 0;
        }
        &__svg {
          transition: transform .3s;
          height: 24px;
          width: 24px;
          fill: $blu;
        }
        $hdrr: &;
        &.active {
          #{$hdrr} {
            &__svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      &-items {
        padding: 16px 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        border-right: 1px solid #CDCDCD;
        border-left: 1px solid #CDCDCD;
      }
      &.hidden {
        display: none;
      }
      $t:&;
      &.options {
        margin-top: -1px;
        #{$t} {
          &-header, &-items {
            @include _1170 {
              margin: unset;
            }
          }
          &-header {
            cursor: pointer;
            user-select: none;
          }
          &-items {
            display: none;
            &.active {
              display: block;
            }
          }
        }
      }
    }
    &-year {
      display: block;
      padding: 10px 23px;
      cursor: pointer;
      border: 1px solid $blu;
      border-radius: 3px;
      margin: 6px 0;
      font-weight: 900;
      font-size: 18px;
      line-height: 26px;
      color: #333;
      &.active {
        border: 1px solid $red;
        color: $red;
      }
    }
    &-glass {
      padding: 6px 0;
      position: relative;
      cursor: pointer;
      display: flex;
      min-height: 26px;
      align-items: center;
      width: 100%;
      transition: color .3s ease-in-out;
      $cfg: &;
      &-radio {
        background: #FFFFFF;
        border: 1px solid #878787;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        position: relative;
        transition: background-color .3s ease-in-out, border .3s ease-in-out;
        &:before {
          position: absolute;
          content: "";
          transition: background-color .3s ease-in-out, border .3s ease-in-out;
        }
      }
      &__title {
        font-size: 16px;
        line-height: 24px;
        color: $blk;
        margin-left: 12px;
      }
      &:hover {
        #{$cfg} {
          &-radio {
            border: 1px solid $red;
          }
          &__title {
            color: $red;
          }
        }
      }
      &.active {
        #{$cfg} {
          &-radio {
            border: 1px solid $red;
            &:before {
              background: $red;
              height: 12px;
              width: 12px;
              border-radius: 50%;
              left: 6px;
              top: 6px;
            }
          }
        }
      }
    }
    &-option {
      display: flex;
      cursor: pointer;
      width: 100%;
      align-items: center;
      &__title {
        font-size: 16px;
        margin-left: 12px;
        line-height: 24px;
        color: $blk;
      }
      padding: 6px 0;
      &-check {
        width: 26px;
        height: 26px;
        border-radius: 3px;
        background-color: #fff;
        border: 1px solid #878787;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blk;
        &__svg {
          fill: #fff;
          width: 20px;
          height: 20px;
          opacity: 0;
          transition: opacity .3s ease-in;
        }
      }
      $cfo: &;
      &:hover {
        #{$cfo} {
          &-check {
            border: 1px solid $red;
          }
          &__title {
            color: $red;
          }
        }
      }
      &.active {
        #{$cfo} {
          &-check {
            border: 1px solid $red;
            background: $red;
            &__svg {
              opacity: 1;
            }
          }
        }
      }
    }
    &-bottom {
      padding: 8px 20px 30px;
      border: 1px solid #CDCDCD;
      border-top: none;
      border-radius: 0 0 3px 3px;
      @include _1170 {
        padding: 25px 30px;
        border: none;
        background: #fff;
        margin-bottom: 20px;
        display: flex;
        border-radius: unset;
      }
      &-clear {
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-top: 8px;
        @include _1170 {
          margin-top: 0;
          width: 40%;
          align-items: center;
        }
        &__btn {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #878787;
          padding-bottom: 3px;
          border-bottom: 1px dashed #878787;
        }
      }
      &__apply {
        width: 100%;
        font-weight: 900;
        font-size: 16px;
        margin-top: 20px;
        background: $blu !important;
        color: #fff !important;
        &:focus, &:hover {
          background: $red !important;
          border: 1px solid $red !important;
        }
        @include _1170 {
          margin-top: 0;
          width: 60%;
        }
        line-height: 24px;
        cursor: pointer;
        height: 53px;
      }
      &__line {
        height: 1px;
        width: calc(100% - 2px);
        margin-left: 1px;
        background: #DFE9F3;
      }
    }
  }
  &-content {
    margin-left: 30px;
    width: 100%;
    @include _1170 {
      margin-left: 0;
    }
    &-filter {
      height: 53px;
      margin-bottom: 21px;
      &__svg {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }
    }
  }
  &-sort {
    display: flex;
    flex-flow: row wrap;
    &-items {
      display: flex;
      flex-flow: row wrap;
    }
    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      @include _600 {
        width: calc(100% / 2 - 35px);
        @include _480 {
          width: 100%;
        }
      }
      &__svg {
        margin-left: 8px;
        width: 20px;
        height: 20px;
        fill: $blk;
        &.r {
          transform: rotate3d(1, 0, 0, 180deg);
        }
      }
      $self: &;
      &.active {
        #{$self}__svg {
          fill: $red;
        }
      }

    }
    &__text {
      font-size: 16px;
      line-height: 24px;
      margin-right: 35px;
      color: $blk;
      &.wht {
        color: #878787;
        margin-left: 0;
        margin-right: 38px;
      }
      &.active {
        color: $red;
      }
    }
  }
  &-items {
    width: 100%;
    margin-top: 20px;
  }
  &-pagination {
    margin-top: 35px;
    @include _768 {
      margin-top: calc_fluid(25,35,320,768);
    }
  }
  &-text {
    &-sh {
      width: 1170px;
      @include _1290 {
        width: 100%;
      }
    }
    margin-top: calc_fluid(50,70,768,1920);
    margin-bottom: calc_fluid(30,40,768,1920);
    @include _768 {
      margin-top: calc_fluid(40,50,320,768);
      margin-bottom: calc_fluid(15,30,320,768);
    }
  }
}