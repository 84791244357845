.product-card {
  display: flex;
  margin-top: 30px;
  border: 1px solid #CDCDCD;
  @include _1320 {
    flex-flow: row wrap;
  }
  &:first-child {
    margin-top: 0;
  }
  &-main {
    display: flex;
    width: calc_fluid(600, 810, 1170, 1920);
    min-width: calc_fluid(600, 810, 1170, 1920);
    @include _1320 {
      width: 100%;
      min-width: unset;
      @include _600 {
        flex-flow: row wrap;
      }
    }
  }
  &-image {
    position: relative;
    width: calc_fluid(208,262,768,1920);
    @include _768 {
      width: 208px;
    }
    min-width: 262px;
    @include _600 {
      width: 100%;
      min-width: unset;
    }
    padding: 27px;
    overflow: hidden;
    &__img {
      height: 138px;
      object-fit: cover;
      width: 100%;
      border-radius: 3px;
    }
  }
  &-guarantee {
    position: absolute;
    top: 10px;
    transform: skewX(-35deg);
    background: $blu;
    border-radius: 3px;
    padding: 7px 27px;
    left: -14px;
    &__inner {
      transform: skewX(35deg);
      font-weight: 900;
      font-size: 13.3333px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
  &-content {
    padding: 27px 43px 27px 19px;
    @include _600 {
      padding: 0 27px 20px;
    }
    &-available {
      font-style: italic;
      font-size: 15px;
      line-height: 26px;
      color: $red;
      padding-left: 18px;
      position: relative;
      &:before {
        position: absolute;
        content: "";
        border-radius: 50%;
        top: 10px;
        left: 0;
        height: 6px;
        width: 6px;
        background: $red;
      }
      &.active {
        color: #4FAD50;
        &:before {
          background: #4FAD50;
        }
      }
    }
    &-attr {
      margin-top: 8px;
      @include _600 {
        margin-top: 6px;
      }
      display: flex;
      align-items: center;
      &.price {
        @include _600 {
          justify-content: space-around;
        }
      }
      &__title {
        font-size: 16px;
        color: #878787;
        line-height: 24px;
        @include _768 {
          font-size: calc_fluid(12,15,320,768);
          line-height: calc_fluid(18,22,320,768);
        }
        margin-right: 6px;
      }
    }
    &-brand {
      height: 34px;
      padding: 5px 8px;
      border: 1px solid $blu;
      border-radius: 3px;
      max-width: 108px;
      &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        @include _768 {
          font-size: calc_fluid(12,15,320,768);
          line-height: calc_fluid(18,22,320,768);
        }
        color: $blk;
      }
    }
    &-eurocode {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      @include _768 {
        font-size: calc_fluid(12,15,320,768);
        line-height: calc_fluid(18,22,320,768);
      }
      color: #333333;
      &.d {
        font-weight: normal;
      }
    }
    &-link {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      @include _768 {
        font-size: calc_fluid(12,15,320,768);
        line-height: calc_fluid(18,22,320,768);
      }
      text-decoration-line: underline;
      color: $blu;
    }
  }
  &-prices {
    border-left: 1px solid #CDCDCD;
    width: 100%;
    display: flex;

    &-inner {
      width: 100%;

    }
    align-items: center;
    padding: 12px 25px 12px 35px;
    @include _1320 {
      border-left: none;
      border-top: 1px solid #CDCDCD;
      padding: 20px 27px;
      &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include _600 {
          flex-flow: row wrap;

          &-left {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
          }
        }
      }
      @include _600 {
        padding-bottom: 30px;
      }
    }
  }
  &-price {
    display: flex;
    align-items: center;
    @include _600 {
      width: 100%;
    }
    &__main {
      font-weight: 900;
      font-size: calc_fluid(24,28,768,1920);
      line-height: calc_fluid(30,36,768,1920);
      @include _768 {
        font-size: calc_fluid(18,24,320,768);
        line-height: calc_fluid(22,30,320,768);
      }
      color: $red;
    }
    &__default {
      font-weight: 900;
      font-size: calc_fluid(24,28,768,1920);
      line-height: calc_fluid(30,36,768,1920);
      @include _768 {
        font-size: calc_fluid(18,24,320,768);
        line-height: calc_fluid(22,30,320,768);
      }
      color: $blk;
    }
    &__discount {
      font-size: 18px;
      line-height: 24px;
      text-decoration-line: line-through;
      color: #878787;
      margin-left: 14px;
      margin-top: 3px;
      @include _768 {
        font-size: calc_fluid(13,18,320,768);
        line-height: calc_fluid(16,24,320,768);
      }
    }
  }
  &-order {
    margin-top: 24px;
    width: 100%;
    height: 54px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__top {
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      @include _768 {
        font-size: calc_fluid(11,15,320,768);
        line-height: calc_fluid(15,19,320,768);
      }
      color: #FFFFFF;
    }
    &__bottom {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;
      @include _768 {
        font-size: calc_fluid(10,13,320,768);
        line-height: 13px;
      }
    }
  }
  &-callback {
    cursor: pointer;
    font-weight: 900;
    text-align: center;
    font-size: 16px;
    margin-top: 24px;
    line-height: 24px;
    width: 100%;
    height: 54px;
    @include _768 {
      font-size: calc_fluid(11,16,320,768);
      line-height: calc_fluid(15,24,320,768);
    }
  }
  &-order, &-callback {
    @include _1320 {
      margin-top: 0;
      width: 51.5%;
      @include _768 {
        height: calc_fluid(40,53,320,768);
      }
      @include _600 {
        width: 100%;
        margin-top: 22px;
      }
    }
  }
  &__title {
    margin: 7px 0;
    font-weight: 900;
    font-size: calc_fluid(24,28,768,1920);
    line-height: calc_fluid(30,36,768,1920);
    @include _768 {
      font-size: calc_fluid(18,24,320,768);
      line-height: calc_fluid(22,30,320,768);
    }
    color: $blk;
  }
}